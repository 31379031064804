import PageLayout from "./PageLayout";
import {Button} from "../general/input/Button";

const PageTest = () => {

    const runTest = () => {

    }

    return (
        <PageLayout>
            <div>
                <Button
                    style={{
                        height: "300px",
                        width: "70vw",
                        margin: "100px auto",
                        textAlign: "center",
                        display: "flex"
                    }}
                    onClick={runTest}>
                    <p style={{
                        margin: "auto auto",
                        fontSize: "10em"
                    }}>Test</p>
                </Button>
            </div>
        </PageLayout>
    )

}

export default PageTest;