import NavList from "./NavList";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import NavItem from "./NavItem";
import {useState} from "react";
import {categoryService} from "../../res/dataServices/category";
import {getCategories} from "../../redux/action/categoryActions";
import "./nav.css"
import {useMediaQuery} from "@mui/material";
import useLogOut from "../state/account/useLogOut";
import useUpdateEase from "../../newStructure/services/state/component/useUpdateEase";

const Nav = ({onHide}) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const logOut = useLogOut();

    const { session } = useSelector(
        state => state.local.auth
    )
    const { categoryMap, error, loading } = useSelector(
        state => state.session.category
    )
    const {
        isHovering,
        onMouseEnter,
        onMouseLeave
    } = useUpdateEase();

    if (!categoryMap && !loading && !error) {
        dispatch(getCategories({session}))
    }

    const categoryList = categoryService.getCategoryList(categoryMap);
    const onCategoryClick = (category) => {
        const noSubTabs = !category.children
        if(noSubTabs) {
            navigate("/category/" + category.id);
            setShowProducts(false)
            if(onHide) onHide();
        }
    }

    const smallScreen = useMediaQuery('(max-width:768px)')
    const [ showProducts, setShowProducts ] = useState(false);
    const handleProductClick = () => {
        if(smallScreen) {
            setShowProducts(!showProducts);
        } else {
            navigate("/category/");
        }
    }

    const currentNav = location.pathname.split("/")[1];
    const isCurrentLoc = (nav) => nav === currentNav;

    return (
        <>
            <div className="nav-close-button" onClick={onHide}>
                Close
            </div>
            <NavItem
                text="Products"
                displayState={showProducts ? 1 : 0}
                onClick={handleProductClick}
                isCurrentLoc={isCurrentLoc("category")}
                className={isHovering ? "hover" : null}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                disableClass
            >
                <NavList
                    items={categoryList}
                    itemToText={e => e.name}
                    getItemChildren={e => e.children}
                    onItemClick={onCategoryClick}
                    parentSelected={showProducts}
                />
            </NavItem>
            <NavItem
                text="Promotions"
                onClick={() => navigate("/promotion")}
                isCurrentLoc={isCurrentLoc("promotion")}
            />
            <NavItem
                text="Clearance"
                onClick={() => navigate("/clearance")}
                isCurrentLoc={isCurrentLoc("clearance")}
            />
            <NavItem
                text="Order Entry"
                onClick={() => navigate("/orderEntry")}
                isCurrentLoc={isCurrentLoc("orderEntry")}
            />
            {smallScreen &&
                <NavItem text="Log Out" onClick={() => logOut()}/>
            }
            <div className="nav-buffer" />
        </>
    )

}

export default Nav;