import {useSelector} from "react-redux";

const useSession = () => {

    const { session } = useSelector(
        state => state.local.auth
    )

    const getIsSignedIn = () => {
        return Boolean(session?.bPartnerID);
    }

    const getUserName = () => {
        return session?.userName;
    }

    const isAuthenticated = () => {
        return Boolean(session);
    }

    const getAvailableBusiness = () => {
        return session.accessibleBPs;
    }

    const isSignedIn = getIsSignedIn();

    return {
        sessionData: session,
        isSignedIn,
        getIsSignedIn,
        isAuthenticated,
        getUserName,
        getAvailableBusiness

    }

}

export default useSession;