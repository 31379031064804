import useClearance from "../../../services/state/product/useClearance";
import { useMemo } from "react";
import PageProductID from "./PageProductID";
import {ProductPurchaseInfo} from "../../../../res/product/productPurchaseInfo";
import ProductList from "../../product/ProductList";
import {useNavigate} from "react-router-dom";

const breadcrumbTrail = [ {name: "Home", nav: "/"}, {name: "Clearance", nav: "/clearance", selected:true} ]

const PageClearance = () => {

    const navigate = useNavigate();

    const {
        loading : clearanceLoading,
        clearanceIDs,
    } = useClearance();

    const productIDs = useMemo(
        () => clearanceIDs?.map(id => id.M_Product_ID.id),
        [clearanceIDs]
    );

    const onPageChange = (pageNo, filter) => {
        navigate("/clearance/" + pageNo + filter);
    }

    return (
        <PageProductID
            breadcrumbTrail={breadcrumbTrail}
            loading={clearanceLoading}
            productIDs={productIDs}
            onPageChange={onPageChange}
            getProductList={(products, loading) => (
                <ProductList
                    products={products}
                    loading={loading}
                    getDisplayProduct={ProductPurchaseInfo.getClearanceDisplayProduct}
                />
            )}
        />
    )
}

export default PageClearance;
