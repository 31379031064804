import solrProductRequest from "../../rest/request/product/solrProductRequest";
import {useState} from "react";
import {useSelector} from "react-redux";
import ProductPurchaseInfoRequest from "../../rest/request/product/productPurchaseInfoRequest";
import useRequestTracker from "../request/useRequestTracker";
import useAuthRequest from "../request/useAuthRequest";
import OrderBy from "../../../logic/filter/orderBy";

const getProductRequest = (searchParams, priceListID) => {

    const req = solrProductRequest();
    req.queryMatching("Table_Name", "M_Product")
    req.priceListExclusion(priceListID);

    if(searchParams.getCategoryID() > 0)
        req.queryMatching("categoryChain", searchParams.getCategoryID());

    if(searchParams.getPageSize() > 0) {
        req.pageSize(searchParams.getPageSize());
        if(searchParams.getStartPosition() > 0)
            req.start(searchParams.getStartPosition());
    }

    if(searchParams.getProductIDs()?.length > 0) {
        const list = searchParams.getProductIDs().map(id => ({field: "M_Product_ID", value: String(id)}));
        req.queryMatchingOrList(list);
    }

    const filters = searchParams.getFilterAttributes();
    if(filters) {
        for(const filterId of Object.keys(filters)) {
            if(filterId === '111') {
                const sortType = filters[filterId][0];
                if(sortType === OrderBy.OPTION_A_Z.name) {
                    req.sortBy("descriptionScore asc")
                } else if(sortType === OrderBy.OPTION_Z_A.name) {
                    req.sortBy("descriptionScore desc");
                }

            } else {
                const toFilter = filters[filterId].map(i => ({field: "filters", value: filterId + "=" + i}));
                req.queryMatchingOrList(toFilter);
            }
        }
    }

    return req;

}

const formatProducts = (productInfo, purchaseInfo, loading) => {
    if(!productInfo) return null;

    const loadingInfo = loading;
    if(!purchaseInfo && loadingInfo) return productInfo.products.map(p => ({...p, loadingPurchaseInfo: true }));
    if(!purchaseInfo) return productInfo.products;

    return productInfo.products.map(p => {
        const info = purchaseInfo[p.id];
        if(info)
            return {...p, ...info}
        else
            return {...p, loadingPurchaseInfo: loadingInfo };
    });
}

const useSolrProduct = () => {

    const {
        loading,
        error,
        sendItWithTask
    } = useRequestTracker();
    const {
        loading : pricingLoading,
        sendIt : sendPricing
    } = useAuthRequest();

    const { session } = useSelector(
        state => state.local.auth
    )

    const [ productInfo, setProductInfo ] = useState();
    const [ purchaseInfo, setPurchaseInfo ] = useState();

    const onPurchaseInfoReceived = (result) => {
        const purchaseInfoMap = {};
        result.forEach(p => purchaseInfoMap[p.id] = p);
        setPurchaseInfo(purchaseInfoMap);
    }

    const onProductsReceived = (productInfo) => {
        const isLoggedIn = session?.bPartnerID > 0;
        if(isLoggedIn && productInfo.products.length > 0) {
            const req = ProductPurchaseInfoRequest(session.orgID, session.bPartnerID)
                .productIDs(productInfo.products.map(p => p.id));
            sendPricing(req.sendRequest, onPurchaseInfoReceived);
        }

        setProductInfo({
            count: productInfo.count,
            start: productInfo.start,
            products: productInfo.products.map(p => ({
                ...p,
                isBrochure: !isLoggedIn
            }))
        });
    }

    const getProducts = (searchParams) => {
        const req = getProductRequest(searchParams, session?.priceListID);
        sendItWithTask(req.sendRequest, onProductsReceived);
    }

    const products = formatProducts(productInfo, purchaseInfo, pricingLoading);

    return {
        loading,
        error,
        products: products,
        productCount: productInfo?.count,
        getProducts
    }
}

export default useSolrProduct;